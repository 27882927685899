/* import __COLOCATED_TEMPLATE__ from './checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  findSolution,
  percentDiscountForSolution,
} from 'embercom/lib/billing';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import { DEFAULT_REQUEST_PARAMS, type RequestParams } from 'embercom/services/quote-service';
import _ from 'underscore';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { captureException } from 'embercom/lib/sentry';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Plan from 'embercom/models/plan';
import { Metadata } from 'embercom/models/quote';
import type CheckoutService from 'embercom/services/checkout-service';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  solutionId: number;
}

interface Signature {
  Element: any;
  Args: Args;
}

interface StripeElement {
  mount: (selector: string) => void;
}

enum addressValidationCountryCodes {
  unitedStates = 'US',
  canada = 'CA',
}

// https://www.intercom.com/help/en/articles/9361928-early-stage-program-from-june-27-2024
const EARLY_STAGE_WITH_COPILOT_PRICING_ARTICLE_ID = 9361928;
// https://www.intercom.com/help/en/articles/9361933-startup-partner-program-from-june-27-2024
const EARLY_STAGE_FREE_WITH_COPILOT_PRICING_ARTICLE_ID = 9361933;

export default class Checkout extends Component<Signature> {
  @service declare appService: any;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare stripev3: any;
  @service declare notificationsService: any;
  @service declare checkoutService: CheckoutService;
  @service declare router: Router;

  @tracked elements: any;
  @tracked confirmingPaymentMethod = false;
  @tracked paymentElementReference!: StripeElement;
  @tracked addressElementReference!: StripeElement;
  @tracked showEarlyStageProgressionModal = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.getQuotes).perform();
    this.checkoutService.setSelectedCopilotAdmins(this.adminIdsWithCopilotSeats);

    this.checkoutService.setCopilotAsInitialised(false);
    if (this.isEarlyStage) {
      this.checkoutService.setSolutionAndPlanIds(this.args.solutionId);
      this.checkoutService.selectedProactiveAddon = true;
      this.checkoutService.showCreditCardPage = true;
      this.checkoutService.enableForceFillCopilotAdmins();
      this.checkoutService.setCopilotAsInitialised(true);
    } else if (!this.checkoutService.isEarlyStageProgression) {
      // Don't setup stripe for early stage progression
      taskFor(this.configureStripeElements).perform();
    }
  }

  get adminIdsWithCopilotSeats() {
    return this.appService.app.humanAdminsWithCopilotSeat.map((admin: any) => admin.id);
  }

  get isEarlyStage() {
    return PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(this.args.solutionId.toString());
  }

  get isEarlyStageFree() {
    return this.args.solutionId.toString() === PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID;
  }

  get earlyStageDiscountAmounts() {
    return {
      yearOne: percentDiscountForSolution(this.args.solutionId.toString(), 1),
      yearTwo: percentDiscountForSolution(this.args.solutionId.toString(), 2),
      yearThree: percentDiscountForSolution(this.args.solutionId.toString(), 3),
    };
  }

  get earlyStageProgramName() {
    let solution = findSolution(this.args.solutionId.toString());
    return this.isEarlyStage ? this.intl.t(solution.marketingNameTranslationKey) : '';
  }

  get showDiscount() {
    return (
      this.checkoutService.yearlyBillingPeriodSelected || this.isEarlyStage || this.isEarlyStageFree
    );
  }

  get isEligibleForAnnualPlan() {
    return (
      this.appService.app.show5_1PricingModal &&
      !this.isEarlyStage &&
      !this.checkoutService.isEarlyStageProgression &&
      (this.appService.app.selfServeAnnualMarketingRelease ||
        this.customerService.customer.everInCardlessTrial ||
        this.customerService.customer.hasNoActiveSubscription)
    );
  }

  get loading() {
    return this.quoteService.loading;
  }

  get quote() {
    if (this.isEarlyStage) {
      return this.earlyStageQuote;
    } else {
      let planIds = [Number(this.checkoutService.selectedPlanId), ...this.nonCorePlanIdsToFetch];
      return this.quoteService.getQuoteById(planIds);
    }
  }

  get earlyStageQuote() {
    return this.quoteService.getFirstQuoteByPricingModelIdentifier(
      this.earlyStagePricingModelIdentifier,
    );
  }

  get quoteWithDefaultPricingModel() {
    return this.quoteService.getFirstQuoteByPricingModelIdentifier(
      this.defaultPricingModelIdentifier,
    );
  }

  get nonCorePlanIdsToFetch() {
    if (this.appService.app.canUseFinAiCopilotAddon && this.appService.app.copilotProduct) {
      return [Number(PROACTIVE_SUPPORT_PLUS_BASE_ID), Number(FIN_AI_COPILOT_BASE_ID)];
    } else {
      return [Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)];
    }
  }

  get seatNumber() {
    //we set the number of seats to the max between current admins in app and minimum mandatory base usage if any
    return Math.max(this.includedMinimumSeats, this.checkoutService.seatNumber);
  }

  get copilotSeatNumber() {
    //Force override of copilot seats to equal seatnumber if required
    return this.checkoutService.forceFillCopilotAdmins
      ? this.seatNumber
      : this.checkoutService.copilotSeatNumber;
  }

  get includedMinimumSeats() {
    return (
      this.quote?.metricPlanLimitMinimum(
        Number(this.checkoutService.selectedPlanId),
        Metric.core_seat_count,
      ) || 0
    );
  }

  get fullSeatPrice() {
    let fullSeatPriceQuote = this.isEarlyStage ? this.quoteWithDefaultPricingModel : this.quote;
    return (
      fullSeatPriceQuote?.fullSeatPrice(
        Number(this.checkoutService.selectedPlanId),
        Metric.core_seat_count,
      ) || 0
    );
  }

  get discountedSeatPrice() {
    if (this.isEligibleForAnnualPlan) {
      return (
        this.quote?.discountedAnnualSeatPrice(Number(this.checkoutService.selectedPlanId)) || 0
      );
    } else {
      return this.isEarlyStage
        ? this.quote?.fullSeatPrice(
            Number(this.checkoutService.selectedPlanId),
            Metric.core_seat_count,
          ) || 0
        : 0;
    }
  }

  get discountedSeatFlatFeePrice(): number {
    return (
      this.quote?.fullSeatFlatFeePrice(
        Number(this.checkoutService.selectedPlanId),
        Metric.core_seat_count,
      ) || 0
    );
  }

  get showSeatLabelWithDiscount() {
    return this.isEarlyStage;
  }

  get fullProactiveAddonPrice() {
    let fullProactiveAddonPriceQuote = this.isEarlyStage
      ? this.quoteWithDefaultPricingModel
      : this.quote;
    return (
      fullProactiveAddonPriceQuote?.fullBreakdownAmount(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)) || 0
    );
  }

  get discountedProactiveAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)) || 0;
  }

  get perSeatCopilotAddonPrice() {
    return (
      this.quote?.fullSeatPrice(Number(FIN_AI_COPILOT_BASE_ID), Metric.copilot_seat_count) || 0
    );
  }

  get fullCopilotAddonPrice() {
    if (this.isEarlyStage) {
      let addonPrice =
        this.quoteWithDefaultPricingModel?.fullSeatPrice(
          Number(FIN_AI_COPILOT_BASE_ID),
          Metric.copilot_seat_count,
        ) || 0;
      return addonPrice * this.seatNumber;
    }
    return this.perSeatCopilotAddonPrice * this.checkoutService.selectedCopilotAdmins.length;
  }

  // Used for early stage
  get discountedCopilotAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(FIN_AI_COPILOT_BASE_ID)) || 0;
  }

  get discountedCopilotSeatPrice() {
    return (
      this.quote?.discountedAnnualSeatPrice(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) || 0
    );
  }

  get proactiveSupportBaseUsage() {
    return this.quote?.proactiveSupportBaseUsage;
  }

  get phoneMetricPrice() {
    return this.quote?.phoneMetricPrice(Number(this.checkoutService.solutionId));
  }

  get finMetricPrice() {
    return this.quote?.finMetricPrice(Number(this.checkoutService.selectedPlanId));
  }

  get emailMetricPrice() {
    return this.quote?.emailMetricPrice(Number(this.checkoutService.selectedPlanId));
  }

  get smsMetricPrice() {
    return this.quote?.smsMetricPrice(Number(this.checkoutService.selectedPlanId));
  }

  get whatsAppMetricPrice() {
    return this.quote?.whatsAppMetricPrice(Number(this.checkoutService.selectedPlanId));
  }

  get proactiveSupportMetricPrice() {
    return this.quote?.proactiveSupportMetricPrice;
  }

  get expectedTrialEndDate() {
    if (this.isEarlyStage) {
      let trialEndDate = new Date();
      trialEndDate.setDate(trialEndDate.getDate() + 14);
      return trialEndDate;
    } else {
      return undefined;
    }
  }

  get earlyStagePricingArticleId() {
    return this.isEarlyStageFree
      ? EARLY_STAGE_FREE_WITH_COPILOT_PRICING_ARTICLE_ID
      : EARLY_STAGE_WITH_COPILOT_PRICING_ARTICLE_ID;
  }

  get monthlyProactiveAddonPrice() {
    return _.isNumber(this.discountedProactiveAddonPrice)
      ? this.discountedProactiveAddonPrice
      : this.fullProactiveAddonPrice;
  }

  get monthlyCopilotAddonPriceForEarlyStage() {
    return _.isNumber(this.discountedCopilotAddonPrice)
      ? this.discountedCopilotAddonPrice
      : this.fullCopilotAddonPrice;
  }

  get monthlyCopilotPriceForSeats() {
    let baseSeatPrice = this.checkoutService.yearlyBillingPeriodSelected
      ? this.discountedCopilotSeatPrice
      : this.perSeatCopilotAddonPrice;
    return baseSeatPrice * this.copilotSeatNumber;
  }

  get hasCopilotEarlyStageDiscount() {
    return this.isEarlyStage && this.fullCopilotAddonPrice > this.discountedCopilotAddonPrice;
  }

  get monthlyDiscountedTotal() {
    let total = this.monthlyDiscountedSeatPrice;
    if (this.checkoutService.selectedProactiveAddon) {
      total += this.monthlyProactiveAddonPrice;
    }
    if (this.checkoutService.selectedCopilotAddon) {
      if (!this.hasCopilotEarlyStageDiscount) {
        total += this.monthlyCopilotPriceForSeats;
      } else {
        total += this.monthlyCopilotAddonPriceForEarlyStage;
      }
    }
    return total;
  }

  get totalPrice() {
    let total = 0;
    if (this.isEarlyStage) {
      total = this.monthlyDiscountedTotal;
    } else if (this.checkoutService.yearlyBillingPeriodSelected) {
      total = this.monthlyDiscountedTotal * 12;
    } else {
      total = this.monthlyTotal;
    }
    return total;
  }

  get totalPriceInCents() {
    return this.totalPrice * 100;
  }

  get monthlyFullSeatPrice() {
    return this.fullSeatPrice * this.seatNumber;
  }

  get monthlyDiscountedSeatPrice() {
    return this.discountedSeatFlatFeePrice + this.discountedSeatUsagePrice;
  }

  get additionalSeatUsage() {
    return this.seatNumber - this.includedMinimumSeats;
  }

  get discountedSeatUsagePrice() {
    return this.discountedSeatPrice * this.additionalSeatUsage;
  }

  get monthlyTotal() {
    let total = this.monthlyFullSeatPrice;
    if (this.checkoutService.selectedProactiveAddon) {
      total += this.fullProactiveAddonPrice;
    }
    if (this.checkoutService.selectedCopilotAddon) {
      total += this.fullCopilotAddonPrice;
    }
    return total;
  }

  get backButtonLabel() {
    if (this.checkoutService.showCreditCardPage) {
      return this.intl.t('signup.teams.header.build_your_plan');
    } else if (this.checkoutService.isEarlyStageProgression) {
      return this.intl.t('signup.teams.header.back');
    } else {
      return this.intl.t('signup.teams.header.choose_your_plan');
    }
  }

  @action
  async updateShowCreditCardPage() {
    if (
      this.totalPriceInCents !== this.checkoutService.stripeIntent?.amount &&
      this.checkoutService.stripeIntent?.isPaymentIntent
    ) {
      try {
        await taskFor(this.checkoutService.updatePaymentIntent).perform(this.totalPriceInCents);
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
        );
        captureException(err, {
          tags: {
            responsibleTeam: 'team-purchase-experience',
            responsible_team: 'team-purchase-experience',
          },
        });
        console.error(err);
        return;
      }
    }
    this.checkoutService.updateShowCreditCardPage();
  }

  @action
  onBackButtonClick() {
    if (this.checkoutService.showCreditCardPage) {
      this.updateShowCreditCardPage();
    } else if (this.checkoutService.isEarlyStageProgression) {
      this.router.transitionTo('apps.app.settings.workspace.billing.update-subscription');
    } else {
      this.backToOnboardingHomeAndOpenConversionModal();
    }
  }

  backToOnboardingHomeAndOpenConversionModal() {
    let queryParams = { action: 'buy_intercom' };
    this.router.transitionTo(this.appService.app.onboardingHomeRoute, { queryParams });
  }

  @action showMeteredMessagesArticle() {
    window.Intercom('showArticle', this.appService.app.meteredMessagesArticleId);
    this.checkoutService.sendAnalyticsEvent({
      action: 'clicked',
      object: 'metered_messages_article',
    });
  }

  @action showEarlyStagePricingArticle() {
    let object = this.isEarlyStageFree
      ? 'early_stage_free_pricing_article'
      : 'early_stage_pricing_article';
    window.Intercom('showArticle', this.earlyStagePricingArticleId);
    this.checkoutService.sendAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }

  @action showProactiveSupportProArticle() {
    window.Intercom('showArticle', this.appService.app.proactiveSupportProArticleId);
    this.checkoutService.sendAnalyticsEvent({
      action: 'clicked',
      object: 'metered_proactive_support_plus_article',
    });
  }

  @action openEarlyStageProgressionModal() {
    this.showEarlyStageProgressionModal = true;
  }

  @action closeEarlyStageProgressionModal() {
    this.showEarlyStageProgressionModal = false;
  }

  @action async updateEarlyStageProgressionPlans() {
    let earlyStageGraduation = this.customerService.earlyStageGraduation;
    earlyStageGraduation.planIds = this.checkoutService.selectedPlanIds;
    earlyStageGraduation.priceSetIdentifier =
      earlyStageGraduation.pricingModelForNonEarlyStagePlans;

    this.checkoutService.sendAnalyticsEvent({
      action: 'clicked',
      object: 'configure_new_subscription_modal',
      context: {
        new_progression_experience: true,
        plan_ids: earlyStageGraduation.planIds,
        price_set_identifier: earlyStageGraduation.priceSetIdentifier,
        proactive_addon: `${this.checkoutService.selectedProactiveAddon}`,
        copilot_addon: `${this.checkoutService.selectedCopilotAddon}`,
        copilot_seat_count: `${this.checkoutService.copilotSeatNumber}`,
      },
    });
    await taskFor(this.saveEarlyStageGraduation).perform();
    this.closeEarlyStageProgressionModal();
    return this.router.transitionTo('apps.app.billing.summary', this.customerService.app.id, {
      queryParams: {
        esChoiceConfirmation: true,
      },
    });
  }

  @dropTask
  *saveEarlyStageGraduation(): TaskGenerator<void> {
    try {
      yield this.customerService.earlyStageGraduation.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.summary.current-and-future-price-component.success-saving-plan'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('billing.summary.current-and-future-price-component.error-saving-plan'),
      );
      captureException(err, {
        tags: {
          responsibleTeam: 'team-growth-opportunities',
          responsible_team: 'team-growth-opportunities',
        },
      });
      console.error(err);
    }
  }

  @keepLatestTask
  *getQuotes(): TaskGenerator<void> {
    let params = this.buildQuoteParams(this.pricingModelIdentifiers, this.planIds);
    yield taskFor(this.quoteService.getQuotes).perform(params);
  }

  get pricingModelIdentifiers() {
    let pricingModelIdentifiers = [this.defaultPricingModelIdentifier];
    if (this.isEarlyStage) {
      pricingModelIdentifiers.push(this.earlyStagePricingModelIdentifier);
    }
    return pricingModelIdentifiers;
  }

  get defaultPricingModelIdentifier() {
    return this.appService.app.show5_1PricingModal || this.checkoutService.isEarlyStageProgression
      ? PricingModelIdentifier.PRICING_5_1
      : PricingModelIdentifier.PRICING_5_0;
  }

  get earlyStagePricingModelIdentifier() {
    return this.args.solutionId.toString() === PRICING_5_X_EARLY_STAGE_SOLUTION_ID
      ? PricingModelIdentifier.PRICING_5_1_COPILOT_EARLY_STAGE
      : PricingModelIdentifier.PRICING_5_1_COPILOT_EARLY_STAGE_FREE;
  }

  get planIds() {
    return this.isEarlyStage ? this.earlyStagePlanIds : this.defaultPlanIdForQuotes;
  }

  get corePlans() {
    return this.checkoutService.corePlans;
  }

  get defaultPlanIdForQuotes() {
    let onlySelfServePlans = this.corePlans.filter((plan: Plan) => plan.selfServe);
    return onlySelfServePlans.map((plan: Plan) => [plan.idAsNumber, ...this.nonCorePlanIdsToFetch]);
  }

  get planIdsForInitialQuote() {
    return [Number(this.checkoutService.selectedPlanId), ...this.nonCorePlanIdsToFetch];
  }

  get earlyStagePlanIds() {
    return [[Number(PRICING_5_X_CORE_ADVANCED_ID), ...this.nonCorePlanIdsToFetch]];
  }

  addressRequiresValidationForCountry(countryCode: string) {
    if (this.appService.app.disableFrontendAddressValidation) {
      return false;
    }

    let countryCodesRequiringChecks: string[] = Object.values(addressValidationCountryCodes);
    return countryCodesRequiringChecks.includes(countryCode);
  }

  buildQuoteParams(pricingModelIdentifiers: PricingModelIdentifier[], planIdsArray: number[][]) {
    let params: RequestParams[] = [];

    pricingModelIdentifiers.forEach((pricingModelIdentifier) => {
      planIdsArray.forEach((planIds) => {
        params.push(this.quoteParamsForModelAndPlans(pricingModelIdentifier, planIds));
      });
    });

    return params;
  }

  quoteParamsForModelAndPlans(pricingModelIdentifier: PricingModelIdentifier, planIds: number[]) {
    return {
      ...DEFAULT_REQUEST_PARAMS,
      planIds,
      pricingModelIdentifier,
      source: 'pricing-five-checkout',
      coreSeatCount: this.checkoutService.seatNumber,
    };
  }

  @keepLatestTask
  *mountStripeElements(): TaskGenerator<void> {
    if (!(this.paymentElementReference && this.addressElementReference)) {
      yield taskFor(this.configureStripeElements).perform();
    }

    try {
      this.paymentElementReference.mount('#payment-element');
      this.addressElementReference.mount('#address-element');
    } catch (err) {
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
      );
      console.error(err);
    }
  }

  @keepLatestTask
  *configureStripeElements(): TaskGenerator<void> {
    try {
      if (!this.quote) {
        let params = this.quoteParamsForModelAndPlans(
          this.defaultPricingModelIdentifier,
          this.planIdsForInitialQuote,
        );
        let metadata = new Metadata(params);
        yield taskFor(this.quoteService.requestPrices).perform(metadata);
      }
      let amount = this.totalPriceInCents || 100;
      let stripeIntent = yield taskFor(this.checkoutService.createPaymentIntent).perform(amount);
      let key = this.checkoutService.getStripeKey();

      if (this.stripev3.stripeOptions?.stripeAccount !== stripeIntent.stripeAccountId) {
        this.stripev3._stripe = null;
      }
      yield this.stripev3.load(key, { stripeAccount: stripeIntent.stripeAccountId });

      let options = {
        loader: 'auto',
        appearance: {
          theme: 'minimal',
        },
        clientSecret: stripeIntent.clientSecret,
      };

      this.elements = this.stripev3.elements(options);

      let addressOptions = { mode: 'billing', fields: { name: 'never' } };

      let paymentElementOptions = {
        layout: 'tabs',
      };

      this.addressElementReference = this.elements.create('address', addressOptions);
      this.paymentElementReference = this.elements.create('payment', paymentElementOptions);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
      );
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      console.error(err);
    }
  }

  private setLoading(loading: boolean) {
    this.confirmingPaymentMethod = loading;
    let addressElement = document.getElementById('address-element');
    let paymentElement = document.getElementById('payment-element');
    if (addressElement) {
      addressElement.style.visibility = loading ? 'hidden' : 'visible';
    }
    if (paymentElement) {
      paymentElement.style.visibility = loading ? 'hidden' : 'visible';
    }
  }

  @dropTask
  *confirmPayment(): TaskGenerator<void> {
    this.setLoading(true);
    let addressElement = this.elements.getElement('address');

    let addressResult = yield addressElement.getValue();
    this.checkoutService.setAddress(addressResult.value.address);
    if (this.addressRequiresValidationForCountry(this.checkoutService.countryCode ?? '')) {
      try {
        yield taskFor(this.checkoutService.validateAddress).perform();
      } catch (err) {
        this.setLoading(false);
        this.checkoutService.sendAnalyticsEvent({
          action: 'errored',
          object: 'pay_now_button',
          context: {
            plans: this.checkoutService.selectedPlanIds,
            billing_period: `${this.checkoutService.billingPeriod}`,
            seat_number: `${this.checkoutService.seatNumber}`,
            copilot_seat_count: `${this.checkoutService.copilotSeatNumber}`,
            proactive_addon: `${this.checkoutService.selectedProactiveAddon}`,
            valid_address: false,
            early_stage_checkout: this.isEarlyStage,
          },
          solutionId: this.checkoutService.solutionId,
        });
        return;
      }
    }

    this.checkoutService.sendAnalyticsEvent({
      action: 'clicked',
      object: 'pay_now_button',
      context: {
        plans: this.checkoutService.selectedPlanIds,
        billing_period: `${this.checkoutService.billingPeriod}`,
        seat_number: `${this.checkoutService.seatNumber}`,
        copilot_seat_count: `${this.checkoutService.copilotSeatNumber}`,
        proactive_addon: `${this.checkoutService.selectedProactiveAddon}`,
        payment_intent: this.checkoutService.stripeIntent?.isPaymentIntent,
        early_stage_checkout: this.isEarlyStage,
      },
      solutionId: this.checkoutService.solutionId,
    });

    // This url and setting of queryParams is only needed if the payment methods requires a redirect
    // The queryParams will be used to build out the state on failure
    // The queryParams will be used to provide the address on success
    let baseUrl = window.location.origin;
    let url = this.router.urlFor('apps.app.teams-checkout.plan', this.appService.app.id, {
      queryParams: this.checkoutService.queryParamsForRedirect,
    });

    let response;
    try {
      response = yield taskFor(this.checkoutService.confirmSetup).perform({
        elements: this.elements,
        url: `${baseUrl}${url}`,
      });
    } catch (err) {
      this.setLoading(false);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
      );
      console.error(err);
      return;
    }
    //This code will never be hit if the payment method requires a redirect
    if (response.error) {
      this.setLoading(false);
      this.handleError(response.error);
      this.checkoutService.sendAnalyticsEvent({
        action: 'errored',
        object: 'confirm_payment',
        context: {
          error_type: response.error?.type,
          decline_code: response.error?.decline_code,
          error_code: response.error?.code,
          early_stage_checkout: this.isEarlyStage,
        },
        solutionId: this.checkoutService.solutionId,
      });
      return;
    }
    let paymentMethod;
    if (this.checkoutService.stripeIntent!.isPaymentIntent) {
      paymentMethod = response.paymentIntent.payment_method;
    } else {
      paymentMethod = response.setupIntent.payment_method;
    }
    yield taskFor(this.checkoutService.convertSubscription).perform(paymentMethod);
  }

  private handleError(error: any) {
    switch (error?.type) {
      case 'card_error':
      case 'validation_error':
        if (error.decline_code === 'lost_card' || error.decline_code === 'stolen_card') {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.stripe.lost-stolen-card'),
          );
        } else {
          this.notificationsService.notifyError(error.message);
        }
        break;
      default:
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::Checkout': typeof Checkout;
  }
}
